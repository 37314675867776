import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { Grid, Typography, Button, makeStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import get from 'lodash/get'

import { RequestsTable } from '../'
import { transformRequestForTable } from '../../lib/transform'
import { fetchRequestsActions } from '../../redux/actions'
import {
  getBackendRequestsData,
  getBackendUserData,
  isBackendRequestsFetching,
} from '../../redux/backend/selectors'
import { useTranslate } from '../../lib/translate'
import { ROUTES } from '../../config'
import useIsUnderaged from '../../lib/customHooks/useIsUnderaged'
import XmassDialog from '../common/XmassDialog/XmassDialog'

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))

const RequestsPage = ({ fetchRequests, requests, user, isFetchingRequests }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const isUnderaged = useIsUnderaged()

  const transformedRequests = Array.isArray(requests)
    ? requests.map(req => transformRequestForTable(req))
    : []

  useEffect(() => {
    fetchRequests()
  }, [fetchRequests])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} container spacing={3}>
        <Grid item>
          <Typography variant="h6">{translate('formFields.customerId')}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" noWrap className={classes.bold}>
            {get(user, 'customerNumber', translate('dashboard.notAvailable'))}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container>
        <Button
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
          component={RouterLink}
          to={ROUTES.CREATE_SAVINGS_PLAN_STEP_ONE}
          className={classes.button}
          disabled={isUnderaged}
        >
          {translate('dashboard.createAnotherPlan')}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <RequestsTable data={transformedRequests} loading={isFetchingRequests} />
      </Grid>
      <XmassDialog />
    </Grid>
  )
}

const mapStateToProps = state => ({
  requests: getBackendRequestsData(state),
  isFetchingRequests: isBackendRequestsFetching(state),
  user: getBackendUserData(state),
})

const mapDispatchToProps = {
  fetchRequests: fetchRequestsActions.requestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestsPage)
